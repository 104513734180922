import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './Pages/Homepage/homepage';
import Mint from './Pages/Product-Purchase/product_purchse';
import ErrorPage from './Pages/ErrorPage/404-page';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            {/* <Route exact path="/" element={<Homepage/>}/> */}
            <Route exact path="/" element={<Mint/>}/>
            <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
