import React, { useEffect } from 'react';
import { Container, Nav, Navbar, Image, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Logo } from '../../Asset/imgIndex';
import './header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { connectWallet } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "./../../redux/data/dataActions";

function Header(props) {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);

    const truncateAddress = (address) => {
        return address.slice(0, 15) + "...";
    }

    const disconnect = () => {
        window.location.reload();
    }


    const getData = async () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            props.onConnectWallet(blockchain);
        }
    }

    useEffect(() => {
        getData();
    }, [blockchain.account]);


    return (
        <>
            <Navbar collapseOnSelect expand="lg">
                <Container fluid>
                    <Row className="mx-auto w-100">
                        <Col lg={11} className="mx-auto">
                            <div className="bees_nav">
                                <div className="logo_proj">
                                    <Navbar.Brand href="https://beejustice.com/">
                                        <Image className="img-fluid" src={Logo} />
                                    </Navbar.Brand>
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                </div>
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className="ms-auto nav_links">
                                        <Nav.Link href="https://beejustice.com/">Home</Nav.Link>
                                        {/* <Nav.Link href="#projects">Projects</Nav.Link>
                                    <Nav.Link href="#roadMap">Roadmap</Nav.Link>
                                    <Nav.Link href="#team">Team</Nav.Link> */}
                                        <Nav.Link href="https://www.dropbox.com/s/yf8xuvwwm2mggas/Bee%20Justice%20WhitePaper%20%28v1.0%29.pdf?dl=0" target="_blank">Whitepaper</Nav.Link>

                                        {blockchain.account !== "" &&
                                            blockchain.smartContract !== null &&
                                            blockchain.errorMsg === "" ?
                                            (
                                                <NavLink className="button"
                                                 onClick={(e) => {
                                                        e.preventDefault();
                                                        disconnect();
                                                    }}
                                                > Disconnect Wallet</NavLink>
                                            ) : (
                                                <NavLink className="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(connectWallet());
                                                        getData();
                                                    }}
                                                >Connect Wallet</NavLink>
                                            )
                                        }
                                    </Nav>
                                </Navbar.Collapse>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
        </>
    )
}

export default Header;
